<template>
  <div class="container_module">
    <div class="center-loader loader__assitance" v-if="loaderOn">
      <LoaderGeneric />
      <div class="">Cargando solicitud...</div>
    </div>
    <div class="container-slots container-base" v-else-if="BoostrapRequest">
      <div class="" @click="getAssitanceFromUrl">click</div>
    </div>
    <div class="" v-else></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import LoaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
export default {
  created() {
    this.$store.dispatch("Assistance/cleanAssistanceSaveRouteParamsId");

    this.$store.dispatch("Config/cleanSwitchTenancy");
    localStorage.setItem("currentModule", "request");

    this.getAssitanceFromUrl();
  },

  components: {
    LoaderGeneric,
  },

  data: () => {
    return {
      loadRequest: false,
      loaderOn: true,
    };
  },

  computed: {
    ...mapGetters("Login", {
      group: "group",
      user_profile: "user_profile",
      company_id: "company_id",
      ViewCompany: "ViewCompany",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapGetters("Assistance", {
      currentCompanyId: "currentCompanyId",
      currentCompanyName: "currentCompanyName",
    }),
    ...mapState({
      isToken: (state) => state.Login.token,
    }),

    BoostrapRequest() {
      return this.loadRequest;
    },
  },

  methods: {
    getAssitanceFromUrl() {
      let id_assitance = null ?? this.$route.params.id;

      let admin_company_id = this.inSwitch
        ? this.idInSwitch
        : this.user_profile.company.id;

      let payload = {
        admin_company_id: admin_company_id,
        id_assitance: id_assitance,
      };

      if (id_assitance != null) {
        this.$store
          .dispatch("Assistance/getSingleAssitanceFromUrl", payload)
          .then(() => {
            let currentCompanyId = this.currentCompanyId;
            let idMainTenant = this.ViewCompany.id;

            if (this.group == "Admin" && idMainTenant != currentCompanyId) {
              this.SwitchTenancy();
              this.loaderOn = false;

              this.$router.push({
                name: "ViewSingleAssistance",
                params: {
                  id: id_assitance,
                },
              });
            } else {
              this.loaderOn = false;
              this.$router.push({
                name: "ViewSingleAssistance",
                params: {
                  id: id_assitance,
                },
              });
            }
          })
          .catch(() => {
            this.loadRequest = false;
            this.loaderOn = false;
          });
      } else {
        this.loaderOn = false;
        this.loadRequest = false;
      }
    },
    SwitchTenancy() {
      let idMainTenant = this.ViewCompany.id;
      let currentCompanyId = this.currentCompanyId;
      let nameCurrentCompany = this.currentCompanyName;
      let data = {
        name: nameCurrentCompany,
        id: idMainTenant,
        idSwitch: currentCompanyId,
      };
      this.$store.dispatch("Config/setSwitchTenancy", data);
    },
  },
};
</script>

<style></style>
