<template>
  <div class="container-slots container__wall">
    <ModalSLA
      name_modal="modal__sla__message"
      size="modal__SLA_v2"
      ref="modal__sla__message"
    />
    <ModaDuplicateFiles
      name_modal="modal__duplicate__file__message"
      size="xl"
      ref="modal__duplicate__file__message"
    />
    <ModalMaxFiles
      name_modal="modal__max__file__message"
      size="xl"
      ref="modal__max__file__message"
    />
    <div class="breadcrumb">
      <span
        class="text_document m-left-none color_gray_i"
        @click="goToDashboardPreStepAssistance"
      >
        Solicitudes >
      </span>

      <span class="text_document"
        >Caso #{{ currentSingleAssistance.caseCode }}
      </span>
    </div>
    <div class="container_base_single_assistance_wall" v-if="flagFilterMobile">
      <!-- container wall messages -->
      <div class="container_left_single_wall">
        <div class="container_message_elements">
          <div class="container__mobile__icons">
            <div class="display__icons">
              <div class="actions left__arrow_wall">
                <ArrowRightButton />
              </div>
              <div class="icon_logo">
                <LogoMA />
              </div>
              <div class="actions" @click="toggleOptionsWall">
                <PlusIcon />
              </div>
            </div>
          </div>
          <div class="title_unnasigned__wall_mobile">
            <div class="title__unnasigned__wall">MisAbogados</div>
            <div class="sub__title__unnasigned__wall">
              Solicitud sin asignar
            </div>
            <div class="icon__link__wall" @click="SlaOpenModal()">
              Ver plazos de asignación
            </div>
          </div>
          <!-- container titles in desktop view -->
          <div class="container__header__wall">
            <div class="left__header__wall">
              <div class="icon_logo">
                <LogoMA />
              </div>
              <div class="">
                <div class="title__unnasigned__wall" v-if="!assigned">
                  MisAbogados
                </div>
                <div class="title__unnasigned__wall" v-else>
                  {{
                    lawyerAssigned != null ? lawyerAssigned.name : "Sin asignar"
                  }}
                </div>

                <div class="">
                  <span class="sub__title__unnasigned__wall" v-if="!assigned">
                    Solicitud sin asignar -
                  </span>
                  <!-- data from assigned lawyer -->
                  <span v-else class="sub__title__unnasigned__wall">
                    Abogado Asesor Comercial
                  </span>
                  <span
                    class="icon__link__wall"
                    v-if="!assigned"
                    @click="SlaOpenModal()"
                  >
                    Ver plazos de asignación</span
                  >
                </div>
              </div>
            </div>
            <div class="right__header__wall">
              <div class="button__back_wall" @click="backAssistanceButton">
                Volver <ArrowRightButton />
              </div>
            </div>
          </div>
          <!-- container messages wall fix in assigned -->
          <div
            class="container__message__wall"
            id="message"
            v-if="messages != null"
          >
            <div class="container__static__message" v-if="assigned">
              <span class="text__fixed__assigned">
                Tu solicitud fue asignada
              </span>
            </div>
            <div class="container__static__message" v-if="assigned">
              <span class="text__fixed__assigned">
                Este es el inicio de tus mensajes directos con
                {{ lawyerAssigned != null ? lawyerAssigned.name : "@" }}
              </span>
            </div>
            <div class="" v-if="loaderMessages">
              <div class="" v-for="message in messages" :key="message.id">
                <chatMessages
                  :message="message"
                  @messageSelectedEmit="updateMessagePinned"
                />
              </div>
            </div>
            <div class="center__loader__messages" v-else>
              <span
                >...cargando mensajes
                <div class="loader_button_quotation_alt"></div
              ></span>
            </div>
          </div>
          <!-- container__mobile__icons on mobile this disappears -->
        </div>

        <!-- container send new messages  asigned lawer -->
        <div class="container_message_wait" v-if="loaderMessageActive">
          <div class="text_not_assigned">Enviando mensajes</div>
          <div class="loader_button_quotation_message"></div>
        </div>
        <div class="container_message_box">
          <div
            :class="
              loaderMessageActive
                ? 'loading_send_message container__message__chat'
                : 'container__message__chat'
            "
            v-if="assigned && participantValidator"
          >
            <div class="chat__wall">
              <textarea
                class="text_area_expand"
                @keyup.enter="expandTextarea()"
                v-model="newMessage"
                placeholder="Escríbele un mensaje a nuestros abogados"
                @keyup="validatorForOnlySpaces()"
              />
            </div>

            <!-- Select file to send -->

            <div class="icons__action__wall">
              <div class="container__wrapper__hover__files__icon">
                <div class="tooltip__key__files">
                  Cada mensaje puede ir acompañado de máximo 5 archivos adjuntos
                  con un total de 1.5232 MB
                </div>
                <div class="input__custom__file_wall_message"></div>
                <span class="span_file">
                  <Clip @click="updateMultipleFile($event)" />
                </span>

                <input
                  type="file"
                  multiple
                  class="input-add"
                  accept="*/*"
                  ref="fileInput"
                  @change="updateMultipleFile($event)"
                />
                <div v-for="(file, index) in filesNameDataTemp" :key="index">
                  <div class="container__pdf__close container_pdf_message_wall">
                    <div class="name__pfd">
                      {{ file.completeName }}
                      <span class="sizes__pdf">({{ file.tamanio }})</span>
                    </div>

                    <div
                      class="close__file"
                      @click="deleteFile(file.name, file.size)"
                    >
                      x
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="
                  (newMessage != '' && textValidator) || files.length > 0
                    ? 'button__chat'
                    : 'desactive_draf_settings'
                "
                @click="addMessage($event)"
              >
                <span class="" v-if="!buttonLoaderSendMssge"> Enviar </span>
                <div class="loader_button_quotation" v-else></div>
              </div>
            </div>
          </div>
          <!-- container send new messages unasigned lawer -->
          <div class="container__message__chat_desactive_not_assigned" v-else>
            <div
              class="text_loader_message_not_assigned"
              v-if="this.group == 'Admin'"
            >
              {{ this.paragraph1 }} <br />
              {{ this.paragraph2 }}
              <span
                class="text_loader_message_not_assigned link_message_not_assigned"
                @click="viewAssigned()"
              >
                click aquí
              </span>
            </div>
            <div
              class="text_loader_message_not_assigned"
              id="errorCliente"
              v-else
            >
              {{ errorMessageNotAssigned }}
            </div>
          </div>
        </div>
      </div>
      <!-- on mobile this disappears -->
      <div class="container_rigth_single_wall">
        <div class="display__icons__rigth_side">
          <div class="actions back__wall" @click="toggleOptionsWall">
            <PlusIcon />
          </div>
        </div>
        <div class="container__titles__wall__leftside">
          <div
            class="selected__tab"
            :class="DefaultInfo ? 'active' : ''"
            @click="selectedActiveWallOption('default')"
          >
            Detalles
          </div>
          <div
            class="selected__tab"
            :class="!DefaultInfo ? 'active' : ''"
            @click="selectedActiveWallOption('Marker')"
          >
            Mensajes marcados
          </div>
        </div>
        <!-- render options selected in header right opt -->
        <div v-if="DefaultInfo">
          <BaseInformationMessage />
        </div>
        <div class="container__pinned__rigth" v-else>
          <div class="" v-for="message in messages" :key="message.id">
            <div v-if="message.pinned == true">
              <PinnedMessages
                :message="message"
                @messageSelectedEmit="updateMessagePinned"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- in mobile this will be render in else case of flagfiltermobile -->
    <div class="container_rigth_single_wall_mobile" v-else>
      <div class="display__icons__rigth_side">
        <div class="actions back__wall" @click="toggleOptionsWall">
          <PlusIcon />
        </div>
      </div>
      <div class="container__titles__wall__leftside">
        <div
          class="selected__tab"
          :class="DefaultInfo ? 'active' : ''"
          @click="selectedActiveWallOption('default')"
        >
          Detalles
        </div>
        <div
          class="selected__tab"
          :class="!DefaultInfo ? 'active' : ''"
          @click="selectedActiveWallOption('Marker')"
        >
          Mensajes marcados
        </div>
      </div>
      <div v-if="DefaultInfo">
        <BaseInformationMessage />
      </div>

      <div class="container__pinned__rigth" v-else>
        <div class="" v-for="message in messages" :key="message.id">
          <div class="" v-if="message.pinned == true">
            <PinnedMessages
              :message="message"
              @messageSelectedEmit="updateMessagePinned"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoMA from "../assets/logoMA.svg";
import ArrowRightButton from "../assets/ArrowRightButton.svg";
import PlusIcon from "../assets/plusIcon.svg";
import Clip from "../assets/clip.svg";

import { mapGetters, mapState } from "vuex";
import chatMessages from "../components/MessageWall/chatMessages.vue";
import PinnedMessages from "../components/MessageWall/MessagesPinned.vue";
import ModalSLA from "../components/modals/ModalSLA.vue";
import ModaDuplicateFiles from "../components/modals/modalDuplicateFileMessage.vue";
import ModalMaxFiles from "../components/modals/modalWarningMaxFiles.vue";
import BaseInformationMessage from "../components/MessageWall/BaseInformationMessage.vue";
import Vue from "vue";
import integratedAssistanceServices from "../services/integratedAssistance";

// import getSingleThread from "@/graphql/getThread";
// import MyMutation from "@/graphql/mutations/addMessageNoFile";

import { API, graphqlOperation } from "aws-amplify";
import { GET_MESSAGES_FROM_THREAD } from "@/graphql/queries";
import { Observable } from "rxjs";
import { suscribeToNewMessages, UPDATE_MESSAGE } from "@/graphql/subscriptions";

import { USER_ROLE } from "@/utils/roles";

import {
  createMessage,
  updateMessagePinnedMutation,
  createMessageFile,
} from "@/graphql/mutations";
// import {
//   createMessage,
//   updateMessagePinnedMutation,
//   createMessageFile,
// } from "@/graphql/mutations";

export default {
  components: {
    ModaDuplicateFiles,
    ModalMaxFiles,
    BaseInformationMessage,
    LogoMA,
    ArrowRightButton,
    PlusIcon,
    Clip,
    chatMessages,
    ModalSLA,

    PinnedMessages,
  },
  computed: {
    ...mapGetters("Assistance", {
      currentSingleAssistance: "currentSingleAssistance",
      currentSingleAssistanceBaseInformation:
        "currentSingleAssistanceBaseInformation",
      requestID: "currentSingleAssistanceBaseInformationID",
      lawyerAssigned: "lawyerAssigned",
      attendantAssigned: "attendantAssigned",
      requesterAssigned: "requesterAssigned",
      currentIdCompanyRequester: "currentIdCompanyRequester",

      // flagFilterMobileAssistance: "flagFilterMobileAssistance",
    }),
    ...mapGetters("Config", {
      idInSwitch: "idInSwitch",
      inSwitch: "inSwitch",
    }),
    ...mapState({
      company: (state) => state.Login.company,
    }),
    ...mapGetters("Login", {
      group: "group",
      company_id: "company_id",
      user_id: "user_id",
    }),
  },
  async created() {
    if (this.group == USER_ROLE.ADMIN) {
      //TODO: REFACTOR LOGIC FOR TYPE OF ADMIN INSIDE.
      if (this.lawyerAssigned != null) {
        this.assigned = true;

        this.participantValidator =
          this.lawyerAssigned.id == this.user_id
            ? true
            : this.attendantAssigned.id == this.user_id
            ? true
            : false;

        if (this.participantValidator == false) {
          //caso ADMIN misAbogados usuario no perteneciente a las asignaciones.
          // usuario no esta asignado para el esta solicitud
          this.paragraph1 = "No eres un participante asignado del chat.";
          this.paragraph2 =
            "Para visualizar a los asignados de esta conversación haga ";
        }
      } else {
        //caso ADMIN misAbogados sin asginación
        //no tenemos abogado asignado a la solicitud
        this.assigned = false;
        this.paragraph1 = "No hay abogado asignado.";
        this.paragraph2 =
          "Para visualizar a los asignados de esta conversación haga  ";
      }
    } else {
      if (this.lawyerAssigned != null) {
        let assignedValidator =
          this.requesterAssigned.id == this.user_id ? true : false;
        this.assigned = true;
        this.participantValidator = assignedValidator;
        if (assignedValidator == false) {
          //caso CLIENTE misAbogados no es un participante valido
          // usuario no esta asignado para el esta solicitud

          this.errorMessageNotAssigned =
            "Usted no tiene los permisos necesarios para escribir en esta conversación. Si usted cree que esto es un error, por favor tome contacto con el administrador de su empresa, o bien con el equipo de soporte de MisAbogados";
        }
      } else {
        //caso CLIENTE misAbogados no asignado un abogado
        //usuario no puede escribir hasta que la solicitud sea asignada

        this.assigned = false;

        this.errorMessageNotAssigned =
          "Aún no hay un abogado asignado a tu solicitud. Una vez sea asignado uno, podrás comenzar a escribir";
      }
    }
    this.loadMessages();
    this.suscribeToNewMessages(this.requestID);
  },

  data: () => {
    return {
      files: [],
      filesNames: [],
      filesRender: "",
      DefaultInfo: true,
      flagFilterMobile: true,
      assigned: false,
      newMessage: "",
      totalFiles: "",
      messages: [],
      subscription: null,
      subscriptionUpdate: null,
      newTag: "",
      selectedMessage: { pinned: false },
      filenameCut: "",
      typefileCut: "",
      filenameCutFinal: "",
      filesNameDataTemp: [],
      filesToMutation: null,
      loaderMessageActive: false,
      loaderMessages: false,
      totalSizesSelectedFiles: 0,
      buttonLoaderSendMssge: false,
      errorMessageNotAssigned: "",
      paragraph1: "",
      paragraph2: "",
      textValidator: false,
      participantValidator: false,
      multipleFilesTemplateMessage: [],
      totalSizeMultipleMessage: 0,
    };
  },

  methods: {
    async setLastConversation(data) {
      this.messages = data;

      setTimeout(() => {
        let divElement = document.getElementById("message");
        divElement.scrollTop = divElement.scrollHeight;
      }, 500);
    },
    async loadMessages() {
      try {
        const response = await API.graphql(
          graphqlOperation(GET_MESSAGES_FROM_THREAD, {
            quotation_id: this.requestID,
          })
        );
        // asignacion

        await this.setLastConversation(
          response.data.getMessagesFromThread.messages
        );

        this.loaderMessages = true;
      } catch (error) {
        console.log(error);
      }
    },

    async subscribeToUpdatedMessage() {
      let quotation_id = this.requestID;

      this.subscriptionUpdate = API.graphql(
        graphqlOperation(UPDATE_MESSAGE, { quotation_id })
      ).subscribe({
        next: () => {
          this.loadMessages();
        },
        error: (error) => console.log(error),
      });
    },
    suscribeToNewMessages(quotation_id) {
      const subscription = API.graphql(
        graphqlOperation(suscribeToNewMessages, { quotation_id }),
        { cacheOptions: { fetchPolicy: "no-cache" } }
      ).subscribe({
        next: (data) => {
          const newMessage = data.value.data.suscribeToNewMessages;

          this.messages.push(newMessage);

          setTimeout(() => {
            let divElement = document.getElementById("message");
            divElement.scrollTop = divElement.scrollHeight;
          }, 600);
        },
        error: (error) => {
          console.error("Error subscribing to new messages:", error);
        },
      });
      this.subscription = new Observable((observer) => {
        observer.add(subscription);
      });
    },

    async addMessage(e) {
      const MAXIMO_TAMANIO_BYTES = 15971520;
      if (this.files.length > 0) {
        if (this.totalSizesSelectedFiles < MAXIMO_TAMANIO_BYTES) {
          let id_company_requester = this.currentIdCompanyRequester;
          let id_request = this.requestID;

          //TODO: inicializar loader de espacio de mensajeria
          this.loaderMessageActive = true;
          let payload = new FormData();

          this.files.forEach((file) => {
            payload.append("attachments", file);
          });

          await integratedAssistanceServices
            .uploadFiles(id_company_requester, id_request, payload)
            .then((data) => {
              this.filesToMutation = data;

              //TODO: transformar a como lo necesita appsync
            })
            .catch((error) => {
              this.loaderMessageActive = false;
              let errorMessageChat = "No se pudo enviar el mensaje";
              Vue.toasted.error(errorMessageChat);
              console.log("", error);
            });

          try {
            let text = this.newMessage.trim();
            let quotation_id = this.requestID;

            let user;

            if (this.group == USER_ROLE.ADMIN) {
              user =
                this.lawyerAssigned.user.id == this.user_id
                  ? this.lawyerAssigned.user
                  : this.attendantAssigned.user;
            } else {
              user = this.requesterAssigned.user;
            }

            let input = {
              quotation_id: quotation_id,
              text: text,
              user: user,
              attachments: this.filesToMutation,
            };

            if (input.attachments != null) {
              await API.graphql(
                graphqlOperation(createMessageFile, {
                  quotation_id: input.quotation_id,
                  text: input.text,
                  user: input.user,
                  attachments: input.attachments,
                })
              ).then(() => {
                let admin_company_id = this.inSwitch
                  ? this.idInSwitch
                  : this.company_id;
                integratedAssistanceServices
                  .getSingleAssistance(admin_company_id, quotation_id)
                  .then((data) => {
                    this.$store.dispatch(
                      "Assistance/updateCurrentFiles",
                      data.file_urls
                    );
                  });

                const textarea = this.$el.querySelector("textarea");
                textarea.style.height = "46.8px";
                this.totalSizesSelectedFiles = 0;
                const input = this.$refs.fileInput;
                input.type = "text";
                input.type = "file";
                input.values = null;
                this.filesNameDataTemp = [];
                this.filesNames = [];
                this.filesToMutation = null;
                this.files = [];
                this.newMessage = "";
                e.preventDefault();
              });
            }
          } catch (error) {
            console.error(error);
            let errorMessageChat = "No se pudo enviar el mensaje";
            Vue.toasted.error(errorMessageChat);
          }

          //TODO al final del graphqlOperation apagar bandera de loader
          this.loaderMessageActive = false;
        } else {
          const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
          alert(
            `El tamaño máximo permitido por mensaje es de ${tamanioEnMb} MB`
          );
        }
      }

      // si no hay archivos
      else {
        try {
          let text = this.newMessage.trim();

          let quotation_id = this.requestID;

          let user;

          if (this.group == USER_ROLE.ADMIN) {
            user =
              this.lawyerAssigned.user.id == this.user_id
                ? this.lawyerAssigned.user
                : this.attendantAssigned.user;
          } else {
            user = this.requesterAssigned.user;
          }

          // console.log("quien esta enviando el msje:", user);
          this.newMessage = "";

          let input = {
            quotation_id: quotation_id,
            text: text,
            user: user,
          };
          this.buttonLoaderSendMssge = true;
          await API.graphql(
            graphqlOperation(createMessage, {
              quotation_id: input.quotation_id,
              text: input.text,
              user: input.user,
            })
          );
        } catch (error) {
          console.error(error);
          let errorMessageChat = "No se pudo enviar el mensaje";
          Vue.toasted.error(errorMessageChat);
        }
        const textarea = this.$el.querySelector("textarea");
        textarea.style.height = "46.8px";
        this.filesNames = [];
        this.files = [];
        this.newMessage = "";
        e.preventDefault();
        this.buttonLoaderSendMssge = false;
      }
    },
    validatorForOnlySpaces() {
      let text = this.newMessage;
      let textTrimValidation = text.trim();

      if (textTrimValidation == "") {
        this.newMessage = "";
        this.textValidator = false;
      } else {
        this.textValidator = true;
      }
    },
    async updateMessagePinned(message) {
      this.selectedMessage = true;

      try {
        await API.graphql(
          graphqlOperation(updateMessagePinnedMutation, {
            quotation_id: message.quotation_id,
            id: message.id,
            pinned: !message.pinned,
          })
        );
      } catch (error) {
        console.log("error en conexion:", error);
      }
    },

    backAssistanceButton() {
      let display_id = this.currentSingleAssistance.caseCode;

      setTimeout(() => {
        this.$router.push({
          name: "ViewSingleAssistance",
          params: {
            id: display_id,
          },
        });
      }, 500);
    },
    viewAssigned() {
      this.$store.dispatch("Assistance/activeTabAssigned");
      let display_id = this.currentSingleAssistance.caseCode;

      setTimeout(() => {
        this.$router.push({
          name: "ViewSingleAssistance",
          params: {
            id: display_id,
          },
        });
      }, 500);
    },

    deleteFile(file, size) {
      if (this.totalSizeMultipleMessage == size) {
        this.totalSizeMultipleMessage = 0;
      } else {
        this.totalSizeMultipleMessage = this.totalSizeMultipleMessage - size;
      }

      // search in the template array for all the files who are differente for the selected file (via complete name comparation)
      let arrayForTemplateNames = this.filesNameDataTemp.filter((e) => {
        return e.name !== file;
      });
      // new array with the new values (except the coincidence one)
      this.filesNameDataTemp = arrayForTemplateNames;

      // its the same process , but in this case we work with the array for the service

      let arrayForService = this.files.filter((e) => {
        return e.name !== file;
      });

      // new array with the new values (except the coincidence one)
      this.files = arrayForService;

      // Reset the input of the template , so we can add previous elemnts again
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
    },
    updateFile(e) {
      if (this.files.length <= 4) {
        let file = e.target.files[0];

        let fileNameToCheck = file.name;

        let admin_company_id = this.inSwitch
          ? this.idInSwitch
          : this.company_id;

        let id_request = this.requestID;

        let payload = {
          filename: fileNameToCheck,
        };

        integratedAssistanceServices
          .validateuploadFiles(admin_company_id, id_request, payload)
          .then(() => {})
          .catch(() => {
            this.OpenModalDuplicateFiles();
          });

        // this set the max of the bytes the user can send
        const MAXIMO_TAMANIO_BYTES = 15971520;
        // We evaluate the size of the loaded file, in case is bigger , a alert is show in the template
        if (file.size > MAXIMO_TAMANIO_BYTES) {
          const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
          alert(`El tamaño máximo es ${tamanioEnMb} MB`);
        } else {
          this.totalSizesSelectedFiles += file.size;
          // in case the file have a correct size , we store the this file in diferents variables
          this.files.push(file);
          let flagSizeAlreadyExistfile = this.files.length > 0 ? true : false;

          if (flagSizeAlreadyExistfile) {
            let totalSizeExistfiles = this.files.reduce(
              (suma, element) => suma + element.size,
              0
            );

            if (totalSizeExistfiles > MAXIMO_TAMANIO_BYTES) {
              let arrayForService = this.files.filter((e) => {
                return e.name !== file;
              });
              this.files = arrayForService;
              const input = this.$refs.fileInput;
              input.type = "text";
              input.type = "file";
            }
          }
          // let completeName = file.name;

          // This storage the name of files and the size in the template
          // let filesTemplate;

          // This if/else evaluate for the case we need to transform in "MB" or "KB"
          //MB case
          if (file.size > 1000000) {
            // Math.ceil returns the closest integer greater than or equal to a given number so we can have "nice" number to show in the template
            let tamanioEnMbTemp = Math.ceil(file.size / 1000000);
            let tamanioMb = `${tamanioEnMbTemp}MB`;

            let tempFileNames = {
              completeName: file.name,
              tamanio: tamanioMb,
              name: file.name,
              size: file.size,
            };
            this.filesNameDataTemp.push(tempFileNames);

            if (this.files.length == 1) {
              this.filenameCutFinal = file.name;
            } else if (this.files.length == 2) {
              this.cutNameFile(7);
            } else if (this.files.length == 3) {
              this.cutNameFile(12);
            } else if (this.files.length == 4) {
              this.cutNameFile(23);
            } else if (this.files.length == 5) {
              this.cutNameFile(34);
            }

            // filesTemplate = {
            //   completeName: completeName,
            //   tamanio: tamanioEnKb,
            // };
            // this storage the addition of the total size of the files the user is uploading , so we can evaluate if is valid (service max size requirements)
            this.totalSizesOwnFiles = this.totalSizesOwnFiles + file.size;

            this.filesNames.push(this.filesNameDataTemp);
            // [file1, file2, file3]
            //KB case
          } else {
            let tamanioEnKbTemp = Math.ceil(file.size / 1024);
            let tamanioEnKb = `${tamanioEnKbTemp}KB`;
            let tempFileNames = {
              completeName: file.name,
              tamanio: tamanioEnKb,
              name: file.name,
              size: file.size,
            };
            this.filesNameDataTemp.push(tempFileNames);

            //TODO: evaluar cantidad de archivos files en variable data
            //si el files es 1 mantener nombres normmales
            // si files es 2 cortar: X
            // si files es 3 cortar: X
            // si files es 4 cortar: X
            // si files es 5 cortar: X
            if (this.files.length == 1) {
              this.filenameCutFinal = file.name;
            } else if (this.files.length == 2) {
              this.cutNameFile(7);
            } else if (this.files.length == 3) {
              this.cutNameFile(12);
            } else if (this.files.length == 4) {
              this.cutNameFile(23);
            } else if (this.files.length == 5) {
              this.cutNameFile(34);
            }

            // filesTemplate = {
            //   completeName: completeName,
            //   tamanio: tamanioEnKb,
            // };
            // this storage the addition of the total size of the files the user is uploading , so we can evaluate if is valid (service max size requirements)
            this.totalSizesOwnFiles = this.totalSizesOwnFiles + file.size;

            this.filesNames.push(this.filesNameDataTemp);
            // [file1, file2, file3]
          }
        }
      } else {
        this.OpenModalMaxFiles();
      }
      // We store the file selected from the user in a variable (file)
    },
    updateMultipleFile(e) {
      if (e.target.files.length <= 5) {
        // let file = e.target.files[0];
        let fileArray = e.target.files;
        // let payload = [];

        let admin_company_id = this.inSwitch
          ? this.idInSwitch
          : this.company_id;

        let id_request = this.requestID;

        for (let index = 0; index < fileArray.length; index++) {
          let payload = {
            filename: fileArray[index].name,
          };
          this.totalSizeMultipleMessage =
            fileArray[index].size + this.totalSizeMultipleMessage;
          integratedAssistanceServices
            .validateuploadFiles(admin_company_id, id_request, payload)
            .then(() => {})
            .catch(() => {
              this.OpenModalDuplicateFiles();
            });
        }

        // let fileNameToCheck = file.name;

        // let payload = {
        //   filename: fileNameToCheck,
        // };

        // for (let index = 0; index < fileArray.length; index++) {
        //   this.totalSizeMultipleMessage =
        //     fileArray[index].size + this.totalSizeMultipleMessage;
        // }

        // this set the max of the bytes the user can send
        const MAXIMO_TAMANIO_BYTES = 15971520;
        // We evaluate the size of the loaded file, in case is bigger , a alert is show in the template
        if (this.totalSizeMultipleMessage > MAXIMO_TAMANIO_BYTES) {
          const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
          alert(`El tamaño máximo es ${tamanioEnMb} MB`);
        } else {
          this.totalSizesSelectedFiles += this.totalSizeMultipleMessage;
          // in case the file have a correct size , we store the this file in diferents variables

          // this.files.push(file);

          for (let index = 0; index < fileArray.length; index++) {
            this.files.push(fileArray[index]);
          }

          let flagSizeAlreadyExistfile = this.files.length > 0 ? true : false;

          if (flagSizeAlreadyExistfile) {
            let totalSizeExistfiles = this.files.reduce(
              (suma, element) => suma + element.size,
              0
            );

            if (totalSizeExistfiles > MAXIMO_TAMANIO_BYTES) {
              let arrayForService = this.files.filter((e) => {
                return e.name !== fileArray[0];
              });
              this.files = arrayForService;
              const input = this.$refs.fileInput;
              input.type = "text";
              input.type = "file";
            }
          }
          // let completeName = file.name;

          // This storage the name of files and the size in the template
          // let filesTemplate;

          // This if/else evaluate for the case we need to transform in "MB" or "KB"
          //MB case
          if (this.totalSizeMultipleMessage > 1000000) {
            // Math.ceil returns the closest integer greater than or equal to a given number so we can have "nice" number to show in the template

            for (let index = 0; index < fileArray.length; index++) {
              let tamanioEnMbTemp = Math.ceil(fileArray[index].size / 1000000);
              let tamanioMb = `${tamanioEnMbTemp}MB`;

              this.filesNameDataTemp.push({
                completeName: fileArray[index].name,
                tamanio: tamanioMb,
                name: fileArray[index].name,
                size: fileArray[index].size,
              });
            }

            for (
              let index = 0;
              index < this.filesNameDataTemp.length;
              index++
            ) {
              if (index == 0) {
                if (fileArray[0].name.length > 95) {
                  this.cutNameFile(95);
                } else {
                  this.filenameCutFinal = fileArray[0].name;
                }
              } else if (index == 1) {
                this.cutNameFile(7);
              } else if (index == 2) {
                this.cutNameFile(12);
              } else if (index == 3) {
                this.cutNameFile(23);
              } else if (index == 4) {
                this.cutNameFile(34);
              }
            }
            // filesTemplate = {
            //   completeName: completeName,
            //   tamanio: tamanioEnKb,
            // };
            // this storage the addition of the total size of the files the user is uploading , so we can evaluate if is valid (service max size requirements)
            this.totalSizesOwnFiles =
              this.totalSizesOwnFiles + this.totalSizeMultipleMessage;

            this.filesNames.push(this.filesNameDataTemp);
            // [file1, file2, file3]
            //KB case
          } else {
            for (let index = 0; index < fileArray.length; index++) {
              let tamanioEnKbTemp = Math.ceil(fileArray[index].size / 1024);
              let tamanioEnKb = `${tamanioEnKbTemp}KB`;

              this.filesNameDataTemp.push({
                completeName: fileArray[index].name,
                tamanio: tamanioEnKb,
                name: fileArray[index].name,
                size: fileArray[index].size,
              });
            }

            //TODO: evaluar cantidad de archivos files en variable data
            //si el files es 1 mantener nombres normmales
            // si files es 2 cortar: X
            // si files es 3 cortar: X
            // si files es 4 cortar: X
            // si files es 5 cortar: X
            for (
              let index = 0;
              index < this.filesNameDataTemp.length;
              index++
            ) {
              if (index == 0) {
                if (fileArray[0].name.length > 95) {
                  this.cutNameFile(95);
                } else {
                  this.filenameCutFinal = fileArray[0].name;
                }
              } else if (index == 1) {
                this.cutNameFile(7);
              } else if (index == 2) {
                this.cutNameFile(12);
              } else if (index == 3) {
                this.cutNameFile(23);
              } else if (index == 4) {
                this.cutNameFile(34);
              }
            }

            // filesTemplate = {
            //   completeName: completeName,
            //   tamanio: tamanioEnKb,
            // };
            // this storage the addition of the total size of the files the user is uploading , so we can evaluate if is valid (service max size requirements)
            this.totalSizesOwnFiles =
              this.totalSizesOwnFiles + this.totalSizeMultipleMessage;

            this.filesNames.push(this.filesNameDataTemp);
            // [file1, file2, file3]
          }
        }
      } else {
        this.OpenModalMaxFiles();
      }
      // We store the file selected from the user in a variable (file)
    },

    SlaOpenModal() {
      let param = "modal__sla__message";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__sla__message;
      element.open();
    },
    cutNameFile(sliceFile) {
      /*--------------------------------
       @Input: File name
       Description: this function evaluate the length of the name of the file and do a format for them
       @Output: File name formatted
      //--------------------------------*/
      this.filesNameDataTemp.map((el, index) => {
        // We separete the file name and the type of file

        //( "filesname" . "typefile"  )
        let arrayNameTemp = el.completeName.split(".");

        // We save the separate the values and we save in 2 variables

        // ("typefile")
        let typefileCut = arrayNameTemp[1];
        // ("filename")
        let filenameCut = arrayNameTemp[0];
        // we evaluate if the string is "too long" , in that case we "cut" the string and we add ".."
        if (sliceFile == 95) {
          el.completeName = `${filenameCut.slice(0, 91)}.${typefileCut}`;
        } else if (filenameCut.length > 7) {
          if (filenameCut.length - sliceFile <= 0) {
            el.completeName = `${filenameCut.slice(0, 7)}.${typefileCut}`;
          } else {
            if (index == 5) {
              el.completeName = `${filenameCut.slice(0, 7)}.${typefileCut}`;
            } else {
              el.completeName = `${filenameCut.slice(
                0,
                filenameCut.length - sliceFile
              )}.${typefileCut}`;
            }
          }
        }
      });

      // front value for html ("Numero de archivos: X")
    },
    OpenModalDuplicateFiles() {
      let param = "modal__duplicate__file__message";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__duplicate__file__message;
      element.open();
    },
    OpenModalMaxFiles() {
      let param = "modal__max__file__message";
      this.$store.dispatch("Config/setModalRef", param);
      let element = this.$refs.modal__max__file__message;
      element.open();
    },

    toggleOptionsWall() {
      this.flagFilterMobile = !this.flagFilterMobile;
    },
    goToDashboardPreStepAssistance() {
      let company_id = this.inSwitch ? this.idInSwitch : this.company_id;

      let payload = {
        admin_company_id: company_id,
        fields:
          "id,title,service_type,requester,display_id,created_at,description,file_urls,permissions,lawyer,attendant",
        limit: "5",
      };

      this.$store.dispatch("Assistance/cleanTagsOfFilter");
      this.$store.dispatch("Assistance/deactiveFilter");
      this.$store.dispatch("Assistance/cleanFiltersResult");
      this.$store.dispatch("Assistance/getAllAssistance", payload);

      this.$router.push({ name: "listRequestIntegrated" });
    },
    selectedActiveWallOption(value) {
      if (value == "default") {
        this.DefaultInfo = true;
      } else {
        this.DefaultInfo = false;
      }
    },
    // expandTextarea() {
    //   const textarea = this.$el.querySelector("textarea");
    //   textarea.style.height = "auto";
    //   textarea.style.height = `${textarea.scrollHeight}px`;
    // },
    expandTextarea() {
      const textarea = this.$el.querySelector("textarea");
      textarea.style.height = this.calcHeight(textarea.value) + "px";
    },
    calcHeight(value) {
      let numberOfLineBreaks = (value.match(/\n/g) || []).length;
      let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
      return newHeight;
    },
  },

  beforeDestroy() {
    this.subscription == null;
    this.subscriptionUpdate == null;
  },
  watch: {
    selectedMessage: {
      handler: function (newVal) {
        if (newVal) {
          this.subscribeToUpdatedMessage();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.center__loader__messages {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    display: flex;
    gap: 0.8rem;
  }
}
.container__static__message:first-child {
  margin-top: 0.4rem;
}
.container__static__message {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.4rem;
}
.text__fixed__assigned {
  width: fit-content;
  background: #bfd8ff;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
}

.container__header__wall {
  @media screen and (max-width: 600px) and (min-width: 200px) {
    display: none;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;

  > .left__header__wall {
    display: flex;
    gap: 0.7rem;

    > div.icon_logo {
      > svg {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background-color: #000032;
      }
    }

    > div .title__unnasigned__wall {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000032;
    }

    > div div {
      > span.icon__link__wall {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        text-decoration-line: underline !important;
        color: #0064ff !important;
      }
      > span.sub__title__unnasigned__wall {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }
    }
  }

  > .right__header__wall {
    display: flex;
    > .button__back_wall {
      display: flex;
      gap: 4px;
      align-items: center;
      height: 40px !important;
      background: #0064ff !important;
      font-family: Conv_HelveticaLTStd-Light;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      border: none;
      width: fit-content;
      transition: all 250ms ease;
      cursor: pointer !important;
      white-space: nowrap;
      color: white;
      svg {
        width: 15px;
        height: 12px;
        fill: white;
        transform: rotate(180deg);
      }
    }
  }
}

.title_unnasigned__wall_mobile {
  display: none;
  @media screen and (max-width: 600px) and (min-width: 200px) {
    display: flex;
  }
}
.title_unnasigned__wall_mobile {
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* elevation / medium */

  > .title__unnasigned__wall {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000032;
  }

  > .sub__title__unnasigned__wall {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  > .icon__link__wall {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline !important;
    color: #0064ff;
  }
}
.container__wall {
  height: 100vh;
}
.container_base_single_assistance_wall {
  height: 85%;
  display: flex;
  gap: 1rem;
}
.container__titles__wall__leftside {
  display: flex;
  // width: calc(100% / 2);

  @media screen and (max-width: 600px) and (min-width: 200px) {
    width: calc(95%);
    margin-left: 0.5rem;
  }

  //TODO: add mediaquery divide in 2
  // width: calc(100% / 2);
  position: relative;

  > .selected__tab {
    font-size: 14px;
    color: #000032;
    cursor: pointer;
    width: 50%;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
  }
  > .selected__tab.active {
    color: #000032 !important;
  }
  > .selected__tab.desactive {
    background-color: rgba(128, 131, 147, 0.2);
    border-radius: 8px 8px 0px 0px;
    transition: all 250ms ease-in-out;
  }
}

.container__titles__wall__leftside > .selected__tab::before {
  transition: all 100ms ease;
  content: "";
  width: calc(100% / 2);
  height: 1px;
  position: absolute;
  top: 98%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* elevation / medium */

  box-shadow: 0px 2px 4px rgba(7, 4, 146, 0.1),
    0px 24px 60px rgba(6, 47, 125, 0.05), 0px 12px 24px rgba(27, 59, 119, 0.05);
}
.container__titles__wall__leftside > .active::before {
  content: "";
  width: calc(100% / 2);
  height: 2px;
  position: absolute;
  background-color: #0064ff;
  top: 96%;
}

//left side of container

.container_left_single_wall {
  transition: all 100ms ease-out;

  @media screen and (max-width: 600px) and (min-width: 200px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
  }
  @media screen and (max-width: 1100px) and (min-width: 601px) {
    width: 65%;
  }
  display: flex;
  flex-direction: column;
  background: var(--container_pre_step);
  width: 75%;
  // padding-left: 34px;
  // padding-right: 18px;
  padding-top: 17px;
  padding-bottom: 1rem;
  color: var(--text-document-draf) !important;
}

//rigth side of container

.container_rigth_single_wall {
  padding: 1rem 0.5rem 1rem 0.5rem;
}
.container_rigth_single_wall_mobile {
  padding: 1rem 1rem;
}

.container_rigth_single_wall,
.container_rigth_single_wall_mobile {
  @media screen and (max-width: 600px) and (min-width: 200px) {
    width: 100%;
  }
  @media screen and (max-width: 1100px) and (min-width: 601px) {
    width: 35%;
  }
  transition: all 250ms ease-in-out;
  background: var(--container_pre_step);
  width: 25%;
  height: 100%;
  color: var(--text-document-draf) !important;
}

.container_rigth_single_wall_mobile {
  display: none;
  @media screen and (max-width: 600px) and (min-width: 200px) {
    display: block;
  }
}
.container_rigth_single_wall {
  display: block;
  @media screen and (max-width: 600px) and (min-width: 200px) {
    display: none;
  }
}

.container_rigth_single_wall .display__icons__rigth_side {
  display: none;
  @media screen and (max-width: 600px) and (min-width: 200px) {
    display: flex;
  }
}

.display__icons__rigth_side {
  > div.actions {
    background: #0064ff;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    > svg {
      transform: rotate(45deg);
    }
  }
}

// > .back__wall{
//
// }
.container__message__wall {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  > div:last-child {
    padding-bottom: 1rem;
  }
}

.container__message__chat,
.container__message__chat_desactive {
  width: 100%;
  left: 29px;
  bottom: 40px;
  border: 1px solid #000032;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 14px;

  > .icons__action__wall {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 14px;

    > .button__chat {
      margin-top: 0px !important;
      width: 61.16px;
    }
  }
}

.container__message__chat_desactive {
  > .chat__wall {
    padding-top: 1rem;
    height: 60px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    overflow-y: auto;
    color: #636579;
    opacity: 0.6;
  }
  > .icons__action__wall {
    > .button__chat {
      margin-top: 0px !important;
      background: rgba(203, 212, 230, 0.3) !important;
      color: #8d97aa;
    }
  }
}

.container__message__chat {
  > .chat__wall {
    height: 80%;
    width: 100%;

    > textarea {
      padding-top: 1rem;
      width: 101%;
      height: 100%;
      border: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #636579;
      resize: none;

      &:focus {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }
}
.icon__link__wall {
  cursor: pointer;
}

// right container base information
.container__base__information {
  margin-right: 17px;
  margin-top: 17px;
}
.container__base__information_chat {
  margin-top: 17px;
}

.field__base__information {
  margin-left: 17px;
  gap: 0.3 rem;
  > .title__container__base {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 25px;
    color: #000032;
  }

  > .sub__title__container__base {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #000032 !important;
  }
}

.container__base__information,
.container__base__information_chat,
.base__information,
.field__base__information,
.files__chat {
  display: flex;
  flex-direction: column;
}

.base__information {
  gap: 14px;
}

.files__chat {
  gap: 14px;
  margin-top: 23px;
  > .files_header_container {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(156, 156, 156, 0.5);

    > div svg {
      fill: #000032 !important;
      stroke: #000032 !important;

      // real stroke #0064FF
    }
    > .files_assistance__subtitle .files_subtitle_text {
      display: flex;
      align-items: center;
    }

    > .files_assistance__subtitle
      .files_subtitle_text
      .tag_assistance_files_container
      .tag_assistance_files {
      background: #619aff !important;
      border-radius: 5px;
      color: white !important;
      height: 30px;
    }
  }
}
.icon_circle_wall {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(156, 156, 156, 0.43);
}

.container__files__elements,
.file__named {
  display: flex;
  flex-direction: column;
}
.container__files__elements {
  margin-left: 20px;
  gap: 0.7rem;
  overflow-x: hidden !important;
}

.file__element {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;
  > svg {
    fill: #0064ff;
    stroke: #0064ff;
    width: 15px;
    height: 25px;
  }
}

.input__custom__file_wall_message {
  display: flex;
  align-items: center;
  gap: 1rem;
  > input {
    width: 50px !important;
  }
}

.container__pinned__rigth {
  overflow: auto;
  height: 90%;
  overflow-x: hidden;
}
.loading_send_message {
  opacity: 0.2 !important;
}
.container_message_wait {
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  gap: 1rem;
  top: 83.8%;
  left: 38%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 600px) and (min-width: 200px) {
    top: 102.8%;
    left: 51%;
  }
}
.text_loader_message_wait {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.loader_button_quotation_message {
  border: 10px solid #0064ff;
  border-radius: 50%;
  border-top: 2px solid #0064ff;
  border-right: 3px solid transparent;
  border-bottom: 2px solid #0064ff !important;
  border-left: 3px solid transparent;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: auto;
}
.loader_button_quotation_send_message {
  border-radius: 50%;
  border-right: 3px solid transparent !important;
  border-left: 3px solid transparent !important;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader_button_quotation_send_message {
  border: 15px solid #0064ff;
  border-top: 3px solid #0064ff;
  border-bottom: 3px solid #0064ff !important;
}
.container__message__chat_desactive_not_assigned {
  width: 100%;
  border-top: 1px solid #a7a7b6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  height: 89px;
  gap: 0.3rem;
}
.text_loader_message_not_assigned {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}
.link_message_not_assigned {
  text-decoration: underline #0064ff !important;
  color: #0064ff !important;
  cursor: pointer;
}

.container__wrapper__hover__files__icon:hover .tooltip__key__files {
  transform: fade-in 500ms;
  transition: all 700ms ease;
  opacity: 1;
  display: block;
  background: #ffffff !important;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  padding: 0.5rem !important;
  border-radius: 17px;
  transform: translateX(4%) translateY(6px);
  color: #000000 !important;
  @media screen and (max-width: 768px) and (min-width: 200px) {
    white-space: normal !important;
    transform: translateX(10%) translateY(6px) !important;
    width: 200px !important;
    text-align: center !important;
    padding: 0rem !important;
  }
}
.container__wrapper__hover__files__icon {
  display: flex;
  white-space: nowrap !important;
  flex-direction: initial !important;
  gap: 1rem;
}
.container__wrapper__hover__files__icon:hover {
  flex-direction: row !important;
  transition: all 1.5s linear;
  > svg {
    position: absolute !important;
    opacity: 0.9;
    z-index: 99999;
  }
}
.tooltip__key__files {
  position: absolute;
  display: none;
  font-size: 11.8px;
  overflow: hidden;
  @media screen and (max-width: 768px) and (min-width: 200px) {
    font-size: 9.8px;
  }
}
.text_area_expand {
  display: block;
  width: 80%;
  // overflow: hidden;
  min-height: 40px;
  max-height: 300px;
  line-height: 20px;
  resize: none;
}
.container_message_elements {
  height: 80%;
  padding-left: 34px;
  padding-right: 18px;
  @media screen and (max-width: 767px) and (min-width: 200px) {
    height: 100% !important;
    padding-bottom: 100px !important;
  }
}
.container_message_box {
  background-color: white;
  padding-left: 34px;
  padding-right: 18px;
}
//TODO:PATCH SOLUTION , WAITING FOR DESIGN SOLUTION (FOR QA PHASE)
.container_pdf_message_wall {
  @media screen and (max-width: 767px) and (min-width: 200px) {
    display: none !important;
  }
}
</style>
