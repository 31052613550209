<template>
  <div >
    <div class="" v-if="!inSwitch">
      <Sidebar />
    </div>
    <div class="" v-else>
      <SidebarAdmin />
    </div>
    <div class="home_content" id="home_content">
      <div class="overlay" id="overlay" v-on:click="closeSibebarMobile"></div>

      <Header v-if="!inSwitch" />
      <HeaderAdmin v-else />

      <slot />
    </div>
  </div>
  <!-- <div class="center-loader" v-else>
    <LoaderGeneric />
  </div> -->
</template>

<script>
// import tokenService from "../service/tokenService";
import Header from "../components/header/Header";
import HeaderAdmin from "../components/headerAdmin/HeaderAdmin.vue";
import Sidebar from "../components/sidebar/Sidebar.vue";
import SidebarAdmin from "../components/sidebarAdmin/SidebarAdmin.vue";
// import LoaderGeneric from "@/components/loaderGeneric/LoadingMA.vue";
import LightModeScript from "@/utils/lightmode.js";
import DarkModeScript from "@/utils/darkmode.js";

import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Header,
    Sidebar,
    SidebarAdmin,
    HeaderAdmin,
    // LoaderGeneric,
  },
  created() {
    // this.check_token();
    if (this.getterAssistanceRouteId == null) {
      let assistanceRouteId = this.$route.params.id;
      this.$store.dispatch(
        "Assistance/assistanceSaveRouteParamsId",
        assistanceRouteId
      );
    }
    this.$store.dispatch("Assistance/cleanSingleAssitance");
  },
  mounted() {
    // setTimeout(() => {
    //   this.check_token();
    // }, 1000);
  },
  data: () => {
    return {
      tokenValidate: false,
    };
  },

  computed: {
    ...mapState({
      isToken: (state) => state.Login.token,
    }),
    ...mapGetters("Config", {
      inSwitch: "inSwitch",
    }),
    ...mapGetters("SettingsApp", {
      defaultValueLenguageUser: "defaultValueLenguageUser",
      defaultValueDarkMode: "defaultValueDarkMode",
      userDarkModeInstanceChange: "userDarkModeInstanceChange",
    }),
    ...mapGetters("Login", {
      client_company_id: "company_id",
      countryCode: "countryCode",
    }),
    ...mapGetters("Assistance", {
      getterAssistanceRouteId: "getterAssistanceRouteId",
    }),
  },

  methods: {
    loadColor() {
      let data = {
        client_company_id: this.client_company_id,
        scope: 0,
        countryCode: this.countryCode,
      };
      this.$store
        .dispatch("SettingsApp/getSettingsInstances", data)
        .then(() => {
          if (this.defaultValueDarkMode == "Desactivado") {
            // apply light mode
            LightModeScript.applyLightmode();
          } else {
            // apply darkmode
            DarkModeScript.applyDarkmoke();
          }
        });
    },
    closeSibebarMobile() {
      // console.log("cuando hago click aqui?");

      if (!this.inSwitch) {
        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.remove("sidebar-menu");
        sidebar.classList.remove("active");
        let overlay = document.getElementById("overlay");

        overlay.classList.remove("overlay-visible");

        let removeLogoComplex = document.getElementById(
          "logo-desktop-complete"
        );

        removeLogoComplex.classList.add("close-btn-sidebar");

        let btnvisible = document.getElementById("btn-mobile-sidebar");

        btnvisible.classList.remove("close-btn-sidebar");
      } else {
        let sidebar = document.querySelector(".sidebarAdm");
        sidebar.classList.remove("sidebar-menu");
        sidebar.classList.remove("active");
        let overlay = document.getElementById("overlay");

        overlay.classList.remove("overlay-visible");

        let removeLogoComplex = document.getElementById(
          "logo-desktop-complete"
        );

        removeLogoComplex.classList.add("close-btn-sidebar");

        let btnvisible = document.getElementById("btn-mobile-sidebar");

        btnvisible.classList.remove("close-btn-sidebar");
      }
    },

    // check_token: function () {
    //   const token = localStorage.getItem("token");
    //   if (!token) {
    //     this.$store.dispatch("Login/logOut", null);
    //     this.$router.push({ name: "LoginPage" });
    //   } else {
    //     tokenService
    //       .verify_token({
    //         token: this.isToken,
    //       })
    //       .then((data) => {
    //         this.tokenValidate = true;
    //         localStorage.setItem("token", data.token);
    //         let token = data.token;
    //         this.$store.dispatch("Login/updateCredentialToken", token);
    //       })
    //       .then(() => {
    //         this.loadColor();
    //       })
    //       .catch(() => {
    //         this.tokenValidate = false;
    //         setTimeout(() => {
    //           this.$store.dispatch("Login/logOut", null);
    //           this.$router.push({ name: "LoginPage" });
    //         }, 1500);
    //       });
    //   }
    // },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "./layout.scss";
</style>
